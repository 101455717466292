import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { url } from '../../../config/connections';

import setAuthToken from '../../../utils/setAuthToken';

import * as actions from './actions';
import * as actionsReports from '../reports';
import * as actionsDownloads from '../downloads';
import * as actionsFavorites from '../favorites';
import * as actionsSplash from '../splash';
import * as actionsB2b from '../b2b';

const loginUser = user => async (dispatch, getState) => {
  dispatch(actions.isLoading(true));
  axios
    .post(`${url()}/user/login`, user)
    .then(res => {
      const { token } = res.data;

      // Set Auth Token on header
      dispatch(actions.setAuthToken(token));
      setAuthToken(token);

      // Decoded token
      const decoded = jwtDecode(token);

      // Save on localStorage
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('username', decoded.name);
      localStorage.setItem('id_cliente', decoded.clientId);

      // Dispatch de new user with decoded token
      dispatch(actions.setCurrentUser(decoded));
      dispatch(actions.isLoading(false));

      dispatch(actions.setShowNews(true));
    })
    .catch(err => {
      dispatch(actions.setError(err));
      dispatch(actions.isLoading(false));
    });
};

const logoutUser = () => async (dispatch, getState) => {
  // Remove token from localStorage
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('id_cliente');
  localStorage.removeItem('username');
  localStorage.removeItem('state');

  // Remove auth header
  dispatch(actions.setAuthToken(false));

  // Set current user to empty object ({})
  dispatch(actions.setCurrentUser({}));

  dispatch(actionsDownloads.duckOperations.cleanState());
  dispatch(actionsReports.duckOperations.cleanState());
  dispatch(actionsFavorites.duckOperations.cleanState());
  dispatch(actionsSplash.duckOperations.cleanState());
  dispatch(actionsB2b.duckOperations.cleanState());
};

const setCurrentUserOp = payload => dispatch => {
  dispatch(actions.setCurrentUser(payload));
};
const setAuthTokenOp = payload => dispatch => {
  dispatch(actions.setAuthToken(payload));
};

const changePassword = data => async (dispatch, getState) => {
  dispatch(actions.changePasswordLoading(true));
  axios
    .post(`${url()}/user/change-password`, data)
    .then(res => {

      // console.log('res > ', res)
      dispatch(actions.changePasswordMessage(res.data.message))
      dispatch(actions.changePasswordLoading(false));

      setTimeout(() => {
        dispatch(actions.changePasswordMessage(''))
      }, 7000);
    })
    .catch(err => {
      // console.log('err > ', err.response.data.message)
      dispatch(actions.changePasswordMessage(err.response.data.message))
      dispatch(actions.changePasswordLoading(false));
      setTimeout(() => {
        dispatch(actions.changePasswordMessage(''))
      }, 3000);
    });
};

const setShowNews = payload => dispatch => {
    dispatch(actions.setShowNews(payload));
};

export default {
  loginUser,
  logoutUser,
  setCurrentUserOp,
  setAuthTokenOp,
  changePassword,
  setShowNews,
};
