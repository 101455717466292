import React from 'react';
/* import PropTypes from 'prop-types'; */
// redux
import { bindActionCreators } from 'redux';
// react-redux connect
import { connect } from 'react-redux';
// nodejs library that concatenates classes
import classnames from 'classnames';
// react library for routing
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { ClipLoader } from 'react-spinners';

// core components
import AuthHeader from 'components/Headers/AuthHeader.jsx';

import * as actions from '../../state/ducks/auth';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/splash');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/splash');
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.type]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true });

    const user = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.actions.loginUser(user);
  }

  render() {
    const { auth } = this.props;
    const { focusedEmail, focusedPassword } = this.state;

    return (
      <>
        <AuthHeader
          title="Bienvenido a Smartweb"
          lead=""
          logo={{
            innerLink: '/',
            imgSrc: require('assets/img/brand/logo-cademsmart.png'),
            imgAlt: '...',
          }}
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-muted text-center mt-2 mb-5">
                    <small>Ingrese su usuario y contraseña</small>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames('mb-3', {
                        focused: focusedEmail,
                      })}>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                          onChange={e => this.handleChange(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                      })}>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Contraseña"
                          type="password"
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                          onChange={e => this.handleChange(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="info"
                        type="button"
                        onClick={e => this.handleSubmit(e)}
                        style={
                          auth.isLoading
                            ? { pointerEvents: 'none', opacity: '0.4' }
                            : {}
                        }>
                        Acceder
                        {this.state.loading && (
                          <ClipLoader
                            css={{
                              marginLeft: 20,
                            }}
                            size={15}
                            color={'#FFF'}
                          />
                        )}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <Link className="text-white" to="/forgot">
                    <small>¿Has olvidado tu contraseña?</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions.duckOperations, dispatch),
  dispatch,
});

const mapStateToProps = state => ({
  auth: state.authReducer,
  errors: state.errors,
});

/* Login.propTypes = {}; */

export default connect(mapStateToProps, mapDispatchToProps)(Login);
