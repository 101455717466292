import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension';

import { loadState, saveState } from '../utils/localStorage'

// Importing reducers
import authReducer from './ducks/auth';
import reportsReducers from './ducks/reports';
import downloadReducers from './ducks/downloads';
import forgotReducers from './ducks/forgot';
import favoriteReducers from './ducks/favorites';
import splashReducers from './ducks/splash'
import b2bReducers from './ducks/b2b'

const reducers = combineReducers({
  authReducer,
  forgotReducers,
  reportsReducers,
  downloadReducers,
  favorites: favoriteReducers,
  splashReducers,
  b2bReducers
});

const middleware = [thunk];

const persistedState = loadState();

const store = createStore(
  reducers,
  persistedState,
  composeWithDevTools(compose(applyMiddleware(...middleware))),
);

store.subscribe(() => {
  saveState(store.getState())
})

export default store;
