import types from './types';

// Get Reports
export const setReports = payload => ({
  type: types.SET_REPORTS,
  payload,
});

// Clean State
export const cleanState = () => ({
  type: types.CLEAN_STATE,
});
