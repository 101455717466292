import types from './types';

// Loading form
export const setEmail = payload => ({
  type: types.SET_EMAIL,
  payload,
});

// Set Status Forgot
export const setStatusForgot = payload => ({
  type: types.SET_STATUS_FORGOT,
  payload,
});

// Loading form
export const setMessageForgot = payload => ({
  type: types.SET_MESSAGE_FORGOT,
  payload,
});
