import axios from 'axios';
import { url } from '../../../config/connections';
import * as actions from './actions';
import * as actionsSplash from '../splash';

export const getFavorites = () => async (dispatch, getState) => {
  dispatch(actionsSplash.duckOperations.getProgress(10));
  const { email } = getState().authReducer.user;
  const data = {
    email
  }
  axios
    .post(`${url()}/favoritos/list`, data)
    .then(res => {
      const payload = res.data;
      dispatch(actions.getFavorites(payload));
      dispatch(actionsSplash.duckOperations.getProgress(15));
    })
    .catch(err => {
      console.log('err', err);
    });
};


export const saveFavorito = (data) => async (dispatch, getState) => {
  let dataToSend = { ...data };
  delete dataToSend["isOpen"];
  delete dataToSend["aperturas"];
  dispatch(actions.loadingFavorito({ loading: true }))
  axios
    .post(`${url()}/favoritos`, dataToSend)
    .then(res => {
      if (res.status === 201) {
        dispatch(getFavorites())
        dispatch(actions.saveFavorito(res.data))
        dispatch(actions.loadingFavorito({ loading: false }));
        dispatch(actions.toogleModalFavorito({ modal: true }))
        setTimeout(() => {
          dispatch(actions.saveFavorito({ message: '' }))
        }, 3000);
      } else {
        dispatch(getFavorites())
        dispatch(actions.saveFavorito(res.data))
        setTimeout(() => {
          dispatch(actions.toogleModalFavorito({ modal: false }))
          dispatch(actions.saveFavorito({ message: '' }))
          dispatch(actions.loadingFavorito({ loading: false }));
        }, 3500);
      }
    })
    .catch(err => {
      console.log('err', err);
      dispatch(actions.saveFavorito(err.response.data))
      dispatch(actions.loadingFavorito({ loading: false }))
    });

};

export const toogleModalFavorito = () => async (dispatch, getState) => {
  const { modal } = getState().favorites;
  dispatch(actions.toogleModalFavorito({ modal: !modal }))
}

export const deleteFavorito = (data) => async (dispatch, getState) => {
  const { _id } = data;
  axios
    .post(`${url()}/favoritos/delete`, { _id: _id })
    .then(res => {
      dispatch(getFavorites())
    })
    .catch(err => {
      console.log('err', err);
    });

};

export const cleanState = () => async (dispatch, getState) => {
  dispatch(actions.cleanState())
}
