import Login from 'views/pages/Login.jsx';
import Forgot from 'views/pages/Forgot.jsx';
import Splash from 'views/pages/SplashScreen.jsx';
import ChangePassword from 'views/pages/ChangePassword.jsx';

import Download from 'views/pages/Download.jsx';
import Report from 'views/pages/Report.jsx';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    layout: '/',
    showSidebar: false
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot,
    layout: '/',
    showSidebar: false
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    layout: "/",
    showSidebar: false,
    private: true
  },
  {
    path: '/splash',
    name: 'Splash',
    component: Splash,
    layout: '/',
    showSidebar: false,
    private: true
  },
  {
    path: '/descarga',
    name: 'Descarga',
    icon: 'ni ni-cloud-download-95 text-info',
    component: Download,
    layout: '/dashboard',
    showSidebar: true
  },
  {
    path: '/reportes',
    name: 'Reportes',
    icon: 'ni ni-single-copy-04 text-info',
    component: Report,
    layout: '/dashboard',
    showSidebar: false
  },
  {
    path: '/reporte/:name',
    name: "Reporte",
    icon: "ni ni-archive-2 text-primary",
    component: Report,
    layout: "/dashboard",
    showSidebar: false
  }
];

export default routes;
