import React from 'react';
/* import PropTypes from 'prop-types'; */
// redux
import { bindActionCreators } from 'redux';
// react-redux connect
import { connect } from 'react-redux';
// nodejs library that concatenates classes
import classnames from 'classnames';
// react library for routing
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  FormFeedback,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { ClipLoader } from 'react-spinners';

// core components
import AuthHeader from 'components/Headers/AuthHeader.jsx';

import * as actions from '../../state/ducks/auth';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      newPassword: '',
      verifyPassword: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { history, auth, report } = this.props;
    const { changePassword } = auth;
    if (changePassword.message === 'Contraseña modificada' ) {
      history.push(`/dashboard/reporte/${report.reports[0].name.toLowerCase()}`);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.verification();

    const data = {
      password: this.state.password,
      newPassword: this.state.newPassword,
      verifyPassword: this.state.verifyPassword,
      id: this.props.auth.user.userId,
    };
    this.props.actions.changePassword(data);
  }

  verification() {
    const { newPassword, verifyPassword } = this.state;
    if (verifyPassword && newPassword !== verifyPassword) {
      return false;
    }
    return true;
  }

  validation() {
    const { newPassword } = this.state;
    if (newPassword && newPassword.length < 6) {
      return false;
    }
    return true;
  }

  render() {
    const { auth, report } = this.props;
    const {
      focusedPassword,
      focusedNewPassword,
      focusedVerifyPassword,
      password,
      newPassword,
      verifyPassword,
    } = this.state;

    return (
      <>
        <AuthHeader
          title="Cambiar Contraseña"
          lead=""
          logo={{
            innerLink: '/',
            imgSrc: require('assets/img/brand/logo-cademsmart.png'),
            imgAlt: '...',
          }}
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-muted text-center mt-2 mb-5">
                    <small>Ingrese su contraseña y su nueva contraseña</small>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                      })}>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="password"
                          placeholder="Contraseña Actual"
                          type="password"
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                          onChange={e => this.handleChange(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <hr className="m-4" />
                    <FormGroup
                      className={classnames({
                        focused: focusedNewPassword,
                      })}>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid
                          name="newPassword"
                          placeholder="Nueva Contraseña"
                          type="password"
                          onFocus={() =>
                            this.setState({ focusedNewPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedNewPassword: false })
                          }
                          onChange={e => this.handleChange(e)}
                        />
                        {!this.validation() &&
                          <FormFeedback className="mt-2">La nueva contraseña debe tener un largo mayor a 6</FormFeedback>
                        }
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusedVerifyPassword,
                      })}>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          invalid
                          name="verifyPassword"
                          placeholder="Repetir Nueva Contraseña"
                          type="password"
                          onFocus={() =>
                            this.setState({ focusedVerifyPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedVerifyPassword: false })
                          }
                          onChange={e => this.handleChange(e)}
                        />
                        {!this.verification() &&
                          <FormFeedback className="mt-2">Las nuevas contraseñas deben ser iguales</FormFeedback>
                        }
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="info"
                        type="button"
                        onClick={e => this.handleSubmit(e)}
                        style={
                          password &&
                          newPassword &&
                          verifyPassword &&
                          this.validation() &&
                          this.verification()
                            ? {}
                            : { pointerEvents: 'none', opacity: '0.4' }
                        }>
                        Cambiar Contraseña
                        {auth.changePassword.loading && (
                          <ClipLoader
                            css={{
                              marginLeft: 20,
                            }}
                            size={15}
                            color={'#FFF'}
                          />
                        )}
                      </Button>
                      <p>{auth.changePassword.message}</p>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <Link
                    className="text-white"
                    to={`/dashboard/reporte/${report.reports[0].name.toLowerCase()}`}>
                    <small>Volver al dashboard</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions.duckOperations, dispatch),
  dispatch,
});

const mapStateToProps = state => ({
  auth: state.authReducer,
  report: state.reportsReducers,
  errors: state.errors,
});

/* Login.propTypes = {}; */

export default connect(mapStateToProps, mapDispatchToProps)(Login);
