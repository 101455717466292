import React from 'react';
// redux
import { bindActionCreators } from 'redux';
// react-redux connect
import { connect } from 'react-redux';
// react library for routing
import { Link } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// Moment
// import moment from 'moment';
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  // ListGroupItem,
  // ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  // Row,
  // Col,
} from 'reactstrap';
// import { DotLoader } from 'react-spinners';

import * as authActions from '../../state/ducks/auth';
import * as b2bActions from '../../state/ducks/b2b';

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  // function that on mobile devices makes the search open
  openSearch = () => {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(() => {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  };

  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  };

  handleClick(e) {
    e.preventDefault();
    this.props.actions.logoutUser();
  }

  handleClickInfo(e) {
    e.preventDefault();
    this.props.actions.getDataInfo()
  }

  render() {
    const { auth } = this.props;
    return (
      <>
        <Navbar
          className={classnames(
            'navbar-top navbar-expand border-bottom',
            { 'navbar-dark bg-info': this.props.theme === 'dark' },
            { 'navbar-light bg-secondary': this.props.theme === 'light' },
          )}>
          <Container fluid>
            <Collapse navbar isOpen>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      'pr-3 sidenav-toggler',
                      { active: this.props.sidenavOpen },
                      { 'sidenav-toggler-dark': this.props.theme === 'dark' },
                    )}
                    onClick={this.props.toggleSidenav}>
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
                <NavItem className="d-sm-none">
                  <NavLink onClick={this.openSearch}>
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem>
                {/* <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link" color="" tag="a" onClick={e => this.handleClickInfo(e)}>
                    <i className="ni ni-bell-55" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-xl py-0 overflow-hidden"
                    right>
                    <div className="px-3 py-3">
                      <h6 className="text-sm text-muted m-0">
                        Información de <strong className="text-info">Data B2B</strong>
                      </h6>
                    </div>
                    <ListGroup flush>
                      { b2b.loading
                      ? (
                        <ListGroupItem
                          className="list-group-item-action">
                          <Row className="justify-content-center align-items-center">
                            <DotLoader
                              size={50}
                              color={'#6B9DD1'}
                            />
                          </Row>
                        </ListGroupItem>
                      ) : (
                        b2b.b2bData.map(i => {
                          return (
                            <ListGroupItem
                              key={i.retail}
                              className="list-group-item-action">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    alt="..."
                                    src={require(`assets/img/retails/${i.retail.toLowerCase()}.png`)}
                                  />
                                </Col>
                                <div className="col ml--2">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <h4 className="mb-0 text-sm">{i.retail}</h4>
                                    </div>
                                  </div>
                                  <p className="text-sm mb-0">
                                    Última fecha: <strong className="text-info">{moment(i.fecha).utc().format('YYYY-MM-DD')}</strong>
                                  </p>
                                </div>
                              </Row>
                            </ListGroupItem>
                          )
                        })
                      )
                    }
                    </ListGroup>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                {/* <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link" color="" tag="a">
                    <i className="ni ni-ungroup" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-lg dropdown-menu-dark bg-default"
                    right>
                    <p>aqui va el servicio de actualizacion de datos b2b</p>
                    <Row className="shortcuts px-4">
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                          <i className="ni ni-calendar-grid-58" />
                        </span>
                        <small>Calendar</small>
                      </Col>
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-orange">
                          <i className="ni ni-email-83" />
                        </span>
                        <small>Email</small>
                      </Col>
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-info">
                          <i className="ni ni-credit-card" />
                        </span>
                        <small>Payments</small>
                      </Col>
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-green">
                          <i className="ni ni-books" />
                        </span>
                        <small>Reports</small>
                      </Col>
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-purple">
                          <i className="ni ni-pin-3" />
                        </span>
                        <small>Maps</small>
                      </Col>
                      <Col
                        className="shortcut-item"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        xs="4"
                        tag="a">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-yellow">
                          <i className="ni ni-basket" />
                        </span>
                        <small>Shop</small>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      {/* <div
                        className="rounded-circle"
                        style={{
                          width: '45px',
                          height: '45px',
                          backgroundColor: 'red',
                        }}>
                        <span>A</span>
                      </div> */}
                      {/* <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt="..."
                          src={require('assets/img/theme/team-4.jpg')}
                        />
                      </span> */}
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {auth.user.name}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link to="/change-password">
                      <DropdownItem>
                        <i className="fas fa-user-lock" />
                        <span>Cambiar Contraseña</span>
                      </DropdownItem>
                    </Link>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={e => this.handleClick(e)}>
                      <i className="fas fa-sign-out-alt" />
                      <span>Salir</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({...authActions.duckOperations, ...b2bActions.duckOperations}, dispatch),
  dispatch,
});

const mapStateToProps = state => ({
  auth: state.authReducer,
  errors: state.errors,
  b2b: state.b2bReducers
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
