const SET_LOADING_FORM = 'SmartWeb/auth/SET_LOADING_FORM';
const SET_AUTH_TOKEN = 'SmartWeb/auth/SET_AUTH_TOKEN';
const SET_CURRENT_USER = 'SmartWeb/auth/SET_CURRENT_USER';
const SET_ERROR = 'SmartWeb/auth/SET_ERROR';
const IS_LOADING = 'SmartWeb/auth/IS_LOADING';
const CHANGE_PASSWORD_MESSAGE = 'SmartWeb/auth/CHANGE_PASSWORD_MESSAGE';
const CHANGE_PASSWORD_LOADING = 'SmartWeb/auth/CHANGE_PASSWORD_LOADING';
const SET_SHOW_NEWS = 'SmartWeb/auth/SET_SHOW_NEWS';

export default {
  SET_LOADING_FORM,
  SET_CURRENT_USER,
  SET_AUTH_TOKEN,
  SET_ERROR,
  IS_LOADING,
  CHANGE_PASSWORD_MESSAGE,
  CHANGE_PASSWORD_LOADING,
  SET_SHOW_NEWS,
};
