import types from './types';

// Loading form
export const setLoadingForm = payload => ({
  type: types.SET_PASSWORD,
  payload,
});

// Send Token to back
export const setAuthToken = token => ({
  type: types.SET_AUTH_TOKEN,
  payload: token,
});

// Set current user
export const setCurrentUser = payload => ({
  type: types.SET_CURRENT_USER,
  payload,
});

// Set current user
export const isAuthenticated = payload => ({
  type: types.SET_CURRENT_USER,
  payload,
});

// Set error
export const setError = payload => ({
  type: types.SET_ERROR,
  payload,
});

// Set Is Loading
export const isLoading = payload => ({
  type: types.IS_LOADING,
  payload,
});

// Set Change Password Message
export const changePasswordMessage = payload => ({
  type: types.CHANGE_PASSWORD_MESSAGE,
  payload,
});

// Set Change Password Loading
export const changePasswordLoading = payload => ({
  type: types.CHANGE_PASSWORD_LOADING,
  payload,
});

export const setShowNews = payload => ({
  type: types.SET_SHOW_NEWS,
  payload,
});