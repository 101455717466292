import axios from 'axios';

import { url } from '../../../config/connections';

import * as actions from './actions';
import * as actionsSplash from '../splash';

const getAperturas = () => async (dispatch, getState) => {
  dispatch(actionsSplash.duckOperations.getProgress(10));
  axios({
    method: 'GET',
    url: `${url()}/apertura`,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => {
    const periodo = res.data;
    dispatch(actionsSplash.duckOperations.getProgress(15));
    dispatch(actions.getAperturas(periodo));
  }).catch(err => {
    console.log('err', err);
    dispatch(actions.getAperturas({}))
  })
};

const getPeriodos = () => async (dispatch, getState) => {
  dispatch(actionsSplash.duckOperations.getProgress(10));
  axios({
    method: 'GET',
    url: `${url()}/apertura/periodo`,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => {
    const periodo = res.data;
    dispatch(actions.getPeriodos(periodo));
    dispatch(actionsSplash.duckOperations.getProgress(15));
  }).catch(err => {
    dispatch(actions.getPeriodos([]));
    console.log('Error: ', err)
  });
};

const downloadData = (data, callback) => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  const aperturaFormated =  data.inputList.reduce((acc, current) => {
    let { tipoApertura, valorApertura } = current
    const { clientId } = getState().authReducer.user

    if (clientId === 56) {
      return {...acc,
        'Distribuidor': ['CAPEL'],
        [tipoApertura]: valorApertura ? valorApertura : []
      }
    }

    return {...acc, [tipoApertura]: valorApertura ? valorApertura : [] }
  }, {})

  const formData = {
    'fuente': { [data.tipoFuente]: data.valorFuente },
    'periodo': { [data.tipoPeriodo]: {
      start: data.inicio,
      end: data.fin,
    }} ,
    'opts': {
      sumarPeriodo: data.sumarPeriodo,
      excluirCerosONulos: data.excluirCerosONulos,
    },
    'apertura': aperturaFormated,
    'TipoDescarga': data.tipoDescarga
  }

  axios({
    method: 'POST',
    url: `${url()}/download/detalle_cola`,
    headers: {
      'Authorization': `Bearer ${getState().authReducer.token}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    data: formData,
  }).then(res => {
    callback(res.data.data.job_id);
  }).catch(error => {
    console.log(error);
  }).finally(() => {
    dispatch(actions.setLoading(false));
  })
};

const cleanState = () => async (dispatch, getState) => {
  dispatch(actions.cleanState())
}

export default {
  getAperturas,
  getPeriodos,
  downloadData,
  // getFavoritos,
  // saveFavorito,
  // toogleModalFavorito,
  cleanState
};
