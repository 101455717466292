import React from 'react';
/* import PropTypes from 'prop-types'; */
// redux
import { bindActionCreators } from 'redux';
// react-redux connect
import { connect } from 'react-redux';
// reactstrap components
import { Card, CardBody, Container, Row } from 'reactstrap';
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.jsx';

import * as actions from '../../state/ducks/reports';

class ReportPage extends React.Component {
  createIframe = reports => {
    return reports.map((value, key) => {
      const matchParams = Object.values(this.props.match.params).toString();
      const name = value.name.toLowerCase();
      if (matchParams === name) {
        return (
          <iframe
            key={key}
            title="iframe"
            width="100%"
            height="681px"
            src={value.url}
            frameBorder={0}
            allowFullScreen={true}
          />
        );
      }
      return null;
    });
  };

  render() {
    const { report } = this.props;
    const { reports } = report;

    return (
      <>
        <SimpleHeader
          name={`Reporte ${Object.values(this.props.match.params).toString()}`}
          parentName=""
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardBody className="p-3">
                  {this.createIframe(reports)}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions.duckOperations, dispatch),
  dispatch,
});

const mapStateToProps = state => ({
  report: state.reportsReducers,
});

/* ReportPage.propTypes = {}; */

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
