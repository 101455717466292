import React from 'react';
// react library for routing
import { Link } from 'react-router-dom';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

class TimelineHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-2">
                <Col lg="6" xs="7">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{' '}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark">
                    <BreadcrumbItem>
                      <Link to="/">
                        <i className={this.props.iconClassName} />
                      </Link>
                    </BreadcrumbItem>
                    { this.props.parentName &&
                      <BreadcrumbItem>
                        <a href="/" onClick={e => e.preventDefault()}>
                          {this.props.parentName}
                        </a>
                      </BreadcrumbItem>
                    }
                    <BreadcrumbItem aria-current="page" className="active">
                      {this.props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default TimelineHeader;
