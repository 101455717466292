import types from './types';

// Get Aperturas
export const getAperturas = payload => ({
  type: types.SET_APERTURAS,
  payload,
});

// Get Periodos
export const getPeriodos = payload => ({
  type: types.SET_PERIODOS,
  payload,
});

// Set Loading
export const setLoading = payload => ({
  type: types.SET_LOADING,
  payload,
});

export const cleanState = () => ({
  type: types.CLEAN_STATE,
});
