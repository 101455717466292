import React from 'react';
import ReactDOM from 'react-dom';
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { Provider } from 'react-redux';

import jwtDecoded from 'jwt-decode';

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

// plugins styles downloaded
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';

// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.0.0';

import * as serviceWorker from './serviceWorker';

import PrivateRoute from '../src/components/common/PrivateRoute';

import AdminLayout from 'layouts/Admin.jsx';
import AuthLayout from 'layouts/Auth.jsx';

// Store File
import store from './state/store';

import * as actions from './state/ducks/auth';

import setAuthorizacionToken from './utils/setAuthToken';

if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthorizacionToken(localStorage.jwtToken);
  // store.dispatch(actions.duckOperations.setAuthTokenOp(localStorage.jwtToken));

  // Decode token and get user info
  const decoded = jwtDecoded(localStorage.jwtToken);

  // Set user and isAuthenticated
  // store.dispatch(actions.duckOperations.setCurrentUserOp(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;

  if (decoded.exp < currentTime) {
    // Logout User
    store.dispatch(actions.duckOperations.logoutUser());
    // Clear current Profile
    // store.dispatch(actions.duckOperations.clearCurrentProfile());

    // Redirect to login
    window.location.href = '/';
  }
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <PrivateRoute
          path="/dashboard"
          render={props => <AdminLayout {...props} />}
        />
        <Route path="/" render={props => <AuthLayout {...props} />} />
        <Redirect from="/" to="/" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
