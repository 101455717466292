import * as actions from './actions';

const getProgress = (percent) => async (dispatch, getState) => {
  const { progress } = getState().splashReducers;

  dispatch(actions.setProgress(progress + percent));
  
};

const cleanState = () => async (dispatch, getState) => {
  dispatch(actions.cleanState())
}

export default {
  getProgress,
  cleanState
};
