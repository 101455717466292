import types from './types';

const initialState = {
  aperturas: {},
  periodos: [],
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_APERTURAS:
      return {
        ...state,
        aperturas: action.payload,
      };
    case types.SET_PERIODOS:
      return {
        ...state,
        periodos: action.payload,
      };
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.CLEAN_STATE:
      return initialState;
    default:
      return state;
  }
}
