import axios from 'axios';
// import jwtDecode from 'jwt-decode';

import { url } from '../../../config/connections';

import * as actions from './actions';

const setEmail = email => async (dispatch, getState) => {
  dispatch(actions.setEmail(email));
};

const forgotPassword = () => async (dispatch, getState) => {
  dispatch(actions.setStatusForgot(true));
  const { email } = getState().forgotReducers;
  const body = { email };

  axios
    .post(`${url()}/user/forgot`, body)
    .then(res => {
      dispatch(actions.setMessageForgot(res.data.message));
      if (res.status === 200) {
        dispatch(actions.setStatusForgot(true));
      } else {
        dispatch(actions.setStatusForgot(false));
      }
      dispatch(actions.setStatusForgot(false))
    })
    .catch(err => {
      console.log('err', err.message);
      dispatch(actions.setMessageForgot(err.response.data.message));
      dispatch(actions.setStatusForgot(false));
    });
};

export default {
  setEmail,
  forgotPassword,
};
