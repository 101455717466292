import types from './types';

// Set Data Info
export const setDataInfo = payload => ({
  type: types.SET_DATA_INFO,
  payload,
});

// Set Loading
export const setLoading = payload => ({
  type: types.SET_LOADING,
  payload,
});

// Clean State
export const cleanState = () => ({
  type: types.CLEAN_STATE,
});
