import React from 'react';
// reactstrap components
import { Button, Input, Row, Col } from 'reactstrap';
// react plugin used to create DropdownMenu for selecting items
import Select from 'react-dropdown-select';

import styled from 'styled-components';

class Apertura extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getAperturas = this.getAperturas.bind(this);
  }
  
  handleChange(e) {
    const { value, name } = e.target;
    const { onChangeApertura, index } = this.props;
    
    onChangeApertura(name, value, index);
  }

  handleChangeMultiple(value, name) {
    const { handleChangeApertura, index } = this.props;
    handleChangeApertura(
      'valorApertura',
      value ? value.map(v => v ? v.value: null) : [],
      index,
    );
  }

  customContentRenderer = ({ props, state, methods }) => (
    <div style={{ cursor: 'pointer' }}>
      {state.values.length === 0 ? (
        <>Seleccione una o varias opciones</>
      ) : (
        <>
          Seleccionados {state.values.length} de {props.options.length}
        </>
      )}
    </div>
  );
  
  customDropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search, 'i');
    return (
      <div>
        <SearchAndToggle>
          <Buttons>
            <div>Buscar y seleccionar:</div>
            {state.values.length === props.options.length ? (
              <Button2 className="clear" onClick={methods.clearAll}>
                Borrar todo
              </Button2>
            ) : (
              <Button2 onClick={methods.selectAll}>Seleccionar todo</Button2>
            )}
          </Buttons>
          <input
            type="text"
            value={state.search}
            onChange={methods.setSearch}
            placeholder="Buscar..."
          />
        </SearchAndToggle>
        <Items>
          {props.options
            .filter(item => regexp.test(item[props.searchBy] || item.label))
            .map(option => {
              return (
                <Item
                  key={option.value}
                  onClick={() => methods.addItem(option)}>
                    <input
                      type="checkbox" 
                      onChange={() => methods.addItem(option)}
                      checked={!!state.values.find(op => op.value === option.value)}
                    />
                  <ItemLabel>{option.label}</ItemLabel>
                </Item>
              );
            })}
        </Items>
      </div>
    );
  };

  getAperturas() {
    const { data, inputList } = this.props;
    return Object.entries(data)
      .reduce((acc, current) => {
        const tipoApertura1 = current[0];
        const { grupo } = current[1];
        return [...acc, { [tipoApertura1]: grupo }];
      }, [])
      .filter(
        a =>
          !inputList
            .map(i => i.tipoApertura)
            .includes(Object.keys(a)[0]),
      ) || [];
  };

  render() {
    const aperturas = this.getAperturas();
    const {
      onRemoveApertura,
      inputList,
      index,
      data,
      inicio,
      fin,
    } = this.props;
    const options = inputList[index].tipoApertura.length
    ? data[inputList[index].tipoApertura].valores.map(option => {
        return { label: option, value: option };
      })
    : [];
    const { tipoApertura, valorApertura } = inputList[index];

    return (
      <Row className="mb-3">
        <Col
          md="5"
          style={
            inicio === '' || fin === ''
              ? { pointerEvents: 'none', opacity: '0.4' }
              : {}
          }>
          <Input
            name="tipoApertura"
            type="select"
            value={tipoApertura}
            onChange={v => this.handleChange(v)}>
            {<option>{tipoApertura || 'Seleccione un tipo de apertura'}</option>}
            {[...new Set(aperturas.map((opt, i) => Object.values(opt)[0]))].map(
              (opt, i) => (
                <optgroup key={i} label={opt}>
                  {aperturas.map((value, key) => {
                    if (Object.values(value)[0] === opt && !inputList.map(i => i.tipoApertura).includes(Object.keys(value)[0])) {
                      return (
                        <option key={key} label={Object.keys(value)[0]}>
                          {Object.keys(value)[0]}
                        </option>
                      );
                    }
                    return null;
                  })}
                </optgroup>
              ),
            )}
          </Input>
        </Col>
        <Col
          md="5"
          style={
            tipoApertura === ''
              ? { pointerEvents: 'none', opacity: '0.4' }
              : {}
          }>
          <Select
            placeholder="Seleccione una o varias opciones"
            disabled={tipoApertura.length === 0}
            multi
            searchable
            options={options}
            values={valorApertura.map(i => ({ label: i, value: i }))}
            onChange={v =>
              this.handleChangeMultiple(v, tipoApertura)
            }
            contentRenderer={this.customContentRenderer}
            dropdownRenderer={this.customDropdownRenderer}
          />
        </Col>
        {inputList.length > 1 && (
          <Col md="1">
            <Button
              className="btn-icon"
              outline
              color="primary"
              type="button"
              onClick={onRemoveApertura}>
              <span className="btn-inner--icon">
                <i className="ni ni-fat-delete" />
              </span>
            </Button>
          </Col>
        )}
      </Row>
    );
  }
}

class Aperturas extends React.Component {
  render() {
    const {
      inputList,
      data,
      tipoPeriodo,
      inicio,
      fin,
      handleChangeApertura,
      onChangeApertura,
      onRemoveApertura,
      onAddApertura,
    } = this.props;

    return (
      <>
        <Row>
          <Col md="2">
            <h2 className="mb-4">3.- Aperturas</h2>
          </Col>
          <Col md="10">
            {inputList.map((item, key) => (
              <Apertura
                {...this.props}
                key={key}
                onRemoveApertura={() => onRemoveApertura(item, key)}
                inputList={inputList}
                index={key}
                onChangeApertura={onChangeApertura}
                handleChangeApertura={handleChangeApertura}
                data={data}
              />
            ))}
            {inputList.length !== Object.entries(data).length && (
              <Button
                style={
                  tipoPeriodo === '' || inicio === '' || fin === '' || inputList[0].tipoApertura === ''
                    ? { pointerEvents: 'none', opacity: '0.4' }
                    : {}
                }
                className="btn-icon"
                outline
                color="primary"
                type="button"
                onClick={onAddApertura}>
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">Agregar</span>
              </Button>
            )}
          </Col>
        </Row>
        <hr />
      </>
    );
  }
}

export default Aperturas;

const SearchAndToggle = styled.div`
  display: flex;
  flex-direction: column;

  input {
    margin: 10px 10px 0;
    line-height: 30px;
    padding: 0px 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    :focus {
      outline: none;
      border: 1px solid #6b9dd1;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  & div {
    margin: 10px 0 0 10px;
    font-weight: 600;
  }
`;

const Button2 = styled.button`
  background: none;
  border: 1px solid #555;
  color: #555;
  border-radius: 3px;
  margin: 10px 10px 0;
  padding: 3px 5px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;

  &.clear {
    color: tomato;
    border: 1px solid tomato;
  }

  :hover {
    border: 1px solid #6b9dd1;
    color: #6b9dd1;
  }
`;

const Items = styled.div`
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
`;

const Item = styled.div`
  display: flex;
  margin: 10px;
  align-items: baseline;
`;

const ItemLabel = styled.div`
  margin: 5px 10px;
`;
