import axios from 'axios';

import { url } from '../../../config/connections';

import * as actions from './actions';
import * as actionsSplash from '../splash';

const getReports = () => async (dispatch, getState) => {
  const { user } = getState().authReducer;
  const { clientId } = user;
  
  clientId === 56 || clientId === 31 || clientId === 15 || clientId === 60
    ? dispatch(actionsSplash.duckOperations.getProgress(10))
    : dispatch(actionsSplash.duckOperations.getProgress(50));

  const data = {
    clientId: user.clientId
  }

  axios({
    method: 'POST',
    url: `${url()}/report/getReports`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  }).then(res => {
    const reports = res.data;
    dispatch(actions.setReports(reports));
    clientId === 56 || clientId === 31 || clientId === 15 || clientId === 60
    ? dispatch(actionsSplash.duckOperations.getProgress(15))
    : dispatch(actionsSplash.duckOperations.getProgress(50));
  });
};

const cleanState = () => async (dispatch, getState) => {
  dispatch(actions.cleanState())
}

export default {
  getReports,
  cleanState
};
