export const datos = (clientId) => {
  // 31 == 'ABI'
  if (clientId === 31) {
    return {'b2b': [
      { label: 'Ventas valor', value: 'ventaValor' },
      { label: 'Ventas unidades', value: 'ventaUnidades' },
      { label: 'Ventas HL', value: 'ventaVolumen' },
      { label: 'Ventas costo', value: 'costo' },
      { label: 'Stock unidades', value: 'stock' },
      { label: 'Stock HL', value: 'volumenStock' },
      { label: 'Stock valorizado', value: 'stockValorizado' },
    ]}
  }
  
  // 60 == 'CIAL'
  if (clientId === 60) {
    return {'b2b': [
      { label: 'Ventas valor', value: 'ventaValor' },
      { label: 'Ventas unidades', value: 'ventaUnidades' },
      { label: 'Ventas UC', value: 'ventaVolumen' },
      { label: 'Ventas costo', value: 'costo' },
      { label: 'Stock unidades', value: 'stock' },
      { label: 'Stock UC', value: 'volumenStock' },
    ]};
  }

  return {'b2b': [
    { label: 'Ventas valor', value: 'ventaValor' },
    { label: 'Ventas unidades', value: 'ventaUnidades' },
    { label: 'Ventas UC', value: 'ventaVolumen' },
    { label: 'Ventas costo', value: 'costo' },
    { label: 'Stock unidades', value: 'stock' },
    { label: 'Stock UC', value: 'volumenStock' },
    // { label: 'Stock UC', value: 'stockValorizado' },
  ]}
};

export const periodos = [
  { label: 'Día', value: 'days' },
  { label: 'Semana', value: 'week' },
  { label: 'Mes', value: 'month' },
  { label: 'Año', value: 'year' },
];

export const tiposExportacion = [
  // { label: 'Excel', value: 'xlsx' },
  { label: 'Formato en columnas', value: 'csv' },
  { label: 'Dato fuente', value: 'source_data'},
];
