import React from 'react';

import { Modal, Card, CardBody } from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Axios from 'axios';
import moment from 'moment';

import * as auth from '../../state/ducks/auth';
import { url } from '../../config/connections';

class News extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showingNews: false,
      news: [],
    };

    this.closeNewsModal = this.closeNewsModal.bind(this);
  }

  componentDidMount() {
    Axios.get(`${url()}/news/visible`).then(res => {
      this.setState({
        news: res.data.data,
        showingNews: (this.props.auth.showNews) && (res.data.data.length > 0),
      });
    }).catch(() => {
      this.closeNewsModal();
    });
  }

  closeNewsModal() {
    this.props.actions.setShowNews(false);

    this.setState({
      showingNews: false,
    });
  }

  render() {
    return <div>
      <Modal id="show-news"
        className="modal-dialog"
        size="lg"
        color="primary"
        isOpen={this.state.showingNews}
        toggle={this.closeNewsModal}>
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h2>Noticias</h2>
              </div>
              {
                this.state.news.length === 0 &&
                <div>No hay noticias recientes</div>
              }
              {
                this.state.news.length > 0 &&
                <div>
                  { this.state.news.map(news => {
                    return <div key={news.id} style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
                      <h3>{ news.title }</h3>
                      <div style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: news.text }}></div>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>Fecha: {moment(news.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
                    </div>
                  })}
                </div>
              }
            </CardBody>
          </Card>
        </div>
      </Modal>
    </div>;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.authReducer,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...auth.duckOperations,
  }, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(News);