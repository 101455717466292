import React from 'react';
// reactstrap components
import { Input, Row, Col } from 'reactstrap';
// react plugin used to create datetimepicker
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/es';

import { periodos } from 'data/download.js';

class Periodo extends React.Component {
  constructor(props) {
    super(props);
    const { tipoPeriodo, inicio, fin } = this.props;
    this.state = {
      tipoPeriodo,
      inicio,
      fin,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { tipoPeriodo, inicio, fin } = this.props;
    if (tipoPeriodo !== prevProps.tipoPeriodo
      || inicio !== prevProps.inicio
      || fin !== prevProps.fin) {
      this.getPeriod(this.props.periodos[0], this.props.periodos[1], tipoPeriodo);

      if (tipoPeriodo === 'month') {
        this.setState({
          tipoPeriodo,
          inicio: inicio ? moment(inicio).format('MMMM-YYYY') : '',
          fin: fin ? moment(fin).format('MMMM-YYYY') : ''
        });
        return;
      }

      if (tipoPeriodo === 'year') {
        this.setState({
          tipoPeriodo,
          inicio: inicio ? moment(inicio).format('YYYY') : '',
          fin: fin ? moment(fin).format('YYYY') : ''
        });
        return;
      }

      this.setState({ tipoPeriodo, inicio, fin });
    }
  }

  /* Funciones Periodos */
  getPeriod(start, end, type) {
    const timeType = type === 'week' ? 'days' : type;

    // moment.locale('es-es');
    const result = [];
    const period = {};

    for (
      let m = moment(start);
      m.diff(
        type === 'month' ? moment(end).subtract(1, 'months') : end,
        timeType,
      ) <= 0;
      m.add(1, timeType)
    ) {
      if (type === 'year') {
        if (m.format('YYYY') <= moment(end).format('YYYY')) {
          result.push(m.format('YYYY'));
        }
      }
      if (type === 'month') {
        result.push(m.format('MMMM-YYYY'));
      }
      if (type === 'days') {
        result.push(m.format('YYYY-MM-DD'));
      }
      if (type === 'week') {
        const startWeek = m.clone().startOf('isoWeek');
        const endWeek = m.clone().endOf('isoWeek');
        const week = endWeek.clone().format('ww-YYYY');
        if (!period[week]) {
          period[week] = {
            max: endWeek.format('YYYY-MM-DD'),
            min: startWeek.format('YYYY-MM-DD'),
          };
          result.push({ [week]: period[week] });
        }
      }
    }
    // moment.locale('en');

    this.setState({
      resultadosPeriodos: result.reverse(),
    });
  }

  valueInputPeriod = (tipoPeriodo, inicio, fin, resultadosPeriodos) => {
    moment.locale('es-es');

    const validInicio = current => {
      current = moment(current.format('YYYY-MM-DD'));

      return (
        current.isSameOrAfter(moment(this.props.periodos[0])) &&
        current.isSameOrBefore(moment(this.props.periodos[1]))
      );
    };
    const validFin = current => {
      current = moment(current.format('YYYY-MM-DD'));

      return (
        current.isSameOrAfter(moment(this.state.inicio)) &&
        current.isSameOrBefore(moment(this.props.periodos[1]))
      );
    };

    switch (tipoPeriodo) {
      case 'days':
        return (
          <Row>
            <Col md="5">
              <ReactDatetime
                inputProps={{
                  placeholder: 'Seleccione una fecha inicio',
                }}
                timeFormat={false}
                isValidDate={validInicio}
                onChange={v => this.handleChangeDay('inicio', v)}
                value={this.state.inicio}
              />
            </Col>
            <Col
              md="5"
              style={
                this.state.inicio === ''
                  ? { pointerEvents: 'none', opacity: '0.4' }
                  : {}
              }>
              <ReactDatetime
                inputProps={{
                  placeholder: 'Seleccione una fecha fin',
                }}
                timeFormat={false}
                isValidDate={validFin}
                onChange={v => this.handleChangeDay('fin', v)}
                value={this.state.fin}
              />
            </Col>
          </Row>
        );
      case 'week':
        return (
          <Row>
            <Col md="5">
              <Input
                name="inicio"
                type="select"
                value={this.state.inicio}
                onChange={v => this.handleChangeDate(v)}>
                {this.state.inicio === '' && (
                  <option>Seleccione una semana de inicio</option>
                )}
                {[
                  ...new Set(
                    resultadosPeriodos.map(y =>
                      Object.keys(y)[0].slice(
                        Object.keys(y)[0].length - 4,
                        Object.keys(y)[0].length,
                      ),
                    ),
                  ),
                ].map((opt, i) => (
                  <optgroup key={i} label={opt}>
                    {resultadosPeriodos.map((item, i) => {
                      if (
                        Object.keys(item)[0].slice(
                          Object.keys(item)[0].length - 4,
                          Object.keys(item)[0].length,
                        ) === opt
                      ) {
                        return (
                          <option
                            key={i}
                            label={`S${Object.keys(item)[0]} (${moment(
                              Object.values(item)[0].min,
                            )
                              .locale('es')
                              .format('DD-MMM')} - ${moment(
                              Object.values(item)[0].max,
                            )
                              .locale('es')
                              .format('DD-MMM')})`}>
                            {Object.values(item)[0].min}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </optgroup>
                ))}
              </Input>
            </Col>
            <Col
              md="5"
              style={
                this.state.inicio === ''
                  ? { pointerEvents: 'none', opacity: '0.4' }
                  : {}
              }>
              <Input
                name="fin"
                type="select"
                value={this.state.fin}
                onChange={v => this.handleChangeDate(v)}>
                {this.state.fin === '' && (
                  <option>Seleccione una semana de fin</option>
                )}
                {[
                  ...new Set(
                    resultadosPeriodos
                      .filter(
                        (item, index) =>
                          index <=
                          resultadosPeriodos.indexOf(
                            resultadosPeriodos.find(
                              (item, i) =>
                                Object.values(item)[0].min ===
                                this.state.inicio,
                            ),
                          ),
                      )
                      .map(y =>
                        Object.keys(y)[0].slice(
                          Object.keys(y)[0].length - 4,
                          Object.keys(y)[0].length,
                        ),
                      ),
                  ),
                ].map((opt, i) => (
                  <optgroup key={i} label={opt}>
                    {resultadosPeriodos
                      .filter(
                        (item, index) =>
                          index <=
                          resultadosPeriodos.indexOf(
                            resultadosPeriodos.find(
                              item =>
                                Object.values(item)[0].min ===
                                this.state.inicio,
                            ),
                          ),
                      )
                      .map((item, i) => {
                        if (
                          Object.keys(item)[0].slice(
                            Object.keys(item)[0].length - 4,
                            Object.keys(item)[0].length,
                          ) === opt
                        ) {
                          return (
                            <option
                              key={i}
                              label={`S${Object.keys(item)[0]} (${moment(
                                Object.values(item)[0].min,
                              )
                                .locale('es')
                                .format('DD-MMM')} - ${moment(
                                Object.values(item)[0].max,
                              )
                                .locale('es')
                                .format('DD-MMM')})`}>
                              {Object.values(item)[0].max}
                            </option>
                          );
                        }
                        return null;
                      })}
                  </optgroup>
                ))}
              </Input>
            </Col>
          </Row>
        );
      case 'month':
        return (
          <Row>
            <Col md="5">
              <Input
                name="inicio"
                type="select"
                value={this.state.inicio}
                onChange={v => this.handleChangeDate(v)}>
                {this.state.inicio === '' && (
                  <option>Seleccione un mes de inicio</option>
                )}
                {[
                  ...new Set(
                    resultadosPeriodos.map(y => y.slice(y.length - 4)),
                  ),
                ].map((opt, i) => (
                  <optgroup key={i} label={opt}>
                    {resultadosPeriodos.map((item, i) => {
                      if (item.slice(item.length - 4) === opt) {
                        return (
                          <option key={i} label={item}>
                            {item}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </optgroup>
                ))}
              </Input>
            </Col>
            <Col
              md="5"
              style={
                this.state.inicio === ''
                  ? { pointerEvents: 'none', opacity: '0.4' }
                  : {}
              }>
              <Input
                name="fin"
                type="select"
                value={this.state.fin}
                onChange={v => this.handleChangeDate(v)}>
                {this.state.fin === '' && (
                  <option>Seleccione una mes de fin</option>
                )}
                {[
                  ...new Set(
                    resultadosPeriodos
                      .filter(
                        (item, index) =>
                          index <=
                          resultadosPeriodos.indexOf(this.state.inicio),
                      )
                      .map(y => y.slice(y.length - 4)),
                  ),
                ].map((opt, i) => (
                  <optgroup key={i} label={opt}>
                    {resultadosPeriodos
                      .filter(
                        (item, index) =>
                          index <=
                          resultadosPeriodos.indexOf(this.state.inicio),
                      )
                      .map((item, i) => {
                        if (item.slice(item.length - 4) === opt) {
                          return (
                            <option key={i} label={item}>
                              {item}
                            </option>
                          );
                        }
                        return null;
                      })}
                  </optgroup>
                ))}
              </Input>
            </Col>
          </Row>
        );
      case 'year':
        return (
          <Row>
            <Col md="5">
              <Input
                name="inicio"
                type="select"
                value={this.state.inicio}
                onChange={v => this.handleChangeDate(v)}>
                {this.state.inicio === '' && (
                  <option>Seleccione un año de inicio</option>
                )}
                {resultadosPeriodos.map((item, i) => (
                  <option key={i} label={item}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
            <Col
              md="5"
              style={
                this.state.inicio === ''
                  ? { pointerEvents: 'none', opacity: '0.4' }
                  : {}
              }>
              <Input
                name="fin"
                type="select"
                value={this.state.fin}
                onChange={v => this.handleChangeDate(v)}>
                {this.state.fin === '' && (
                  <option>Seleccione una año de fin</option>
                )}
                {resultadosPeriodos
                  .filter(
                    (item, index) =>
                      index <= resultadosPeriodos.indexOf(this.state.inicio),
                  )
                  .map((item, i) => (
                    <option key={i} label={item}>
                      {item}
                    </option>
                    ))
                  }
              </Input>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };
  /* Fin Funciones Periodos */

  handleChangeType = v => {
    this.getPeriod(
      this.props.periodos[0],
      this.props.periodos[1],
      v.target.value,
    );
    this.setState({ [v.target.name]: v.target.value });
    this.setState({ inicio: '', fin: '' });

    this.props.handleDate(v.target.name, v.target.value);
  };

  handleChangeDate = v => {
    this.setState({ [v.target.name]: v.target.value });
    if (v.target.name === 'inicio') this.setState({ fin: '' });

    switch (this.state.tipoPeriodo) {
      case 'week':
        this.props.handleDate(v.target.name, v.target.value);
        break;
      case 'month':
        let month;
        if (v.target.name === 'inicio') {
          month = moment(v.target.value, 'MMMM-YYYY')
            .startOf('month')
            .format('YYYY-MM-DD');
        } else if (v.target.name === 'fin') {
          month = moment(v.target.value, 'MMMM-YYYY')
            .endOf('month')
            .format('YYYY-MM-DD');
        }
        return this.props.handleDate(v.target.name, month);
      case 'year':
        let year;
        if (v.target.name === 'inicio') {
          year = moment(v.target.value, 'YYYY')
            .startOf('year')
            .format('YYYY-MM-DD');
        } else if (v.target.name === 'fin') {
          year = moment(v.target.value, 'YYYY')
            .endOf('year')
            .format('YYYY-MM-DD');
        }
        return this.props.handleDate(v.target.name, year);
      default:
        return;
    }

    this.props.handleDate(v.target.name, v.target.value);
  };

  handleChangeDay = (type, v) => {
    this.setState({ [type]: v });
    if (type === 'fin' && moment(v).isBefore(this.state.inicio)) {
      this.setState({ fin: '' });
    }
    if (type === 'inicio') {
      this.setState({ fin: '' });
    }
    this.props.handleDate(type, v.format('YYYY-MM-DD'));
  };

  render() {
    const { resultadosPeriodos } = this.state;
    const {
      tipoPeriodo,
      sumarPeriodo,
      handleChange,
      tipoFuente,
      inicio,
      fin,
      clientId
    } = this.props;

    return (
      <>
        <Row>
          <Col md="2">
            {clientId === 31
              ? <h2 className="mb-4">1.- Periodo</h2>
              : <h2 className="mb-4">2.- Periodo</h2>
            }
          </Col>
          <Col md="10">
            <Row className="mb-3">
              <Col
                md="5"
                style={
                  clientId !== 31 && tipoFuente === ''
                    ? { pointerEvents: 'none', opacity: '0.4' }
                    : {}
                }
                >
                <Input
                  name="tipoPeriodo"
                  type="select"
                  value={this.state.tipoPeriodo}
                  onChange={v => this.handleChangeType(v)}>
                  {tipoPeriodo === '' && (
                    <option>Seleccione un tipo de periodo</option>
                  )}
                  {periodos.map((item, key) => (
                    <option key={key} label={item.label}>
                      {item.value}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col md="5">
                {tipoPeriodo === 'days' && (
                  <Row className="pl-3" style={{ display: 'none' }}>
                    <label
                      className="form-control-label mr-3"
                      htmlFor="exampleDatepicker">
                      Sumar Periodos:
                    </label>

                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        name="sumarPeriodo"
                        checked={sumarPeriodo}
                        onChange={handleChange}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Si"
                      />
                    </label>
                  </Row>
                )}
              </Col>
            </Row>
            {this.valueInputPeriod(
              this.state.tipoPeriodo,
              inicio,
              fin,
              resultadosPeriodos,
            )}
          </Col>
        </Row>

        <hr />
      </>
    );
  }
}

export default Periodo;
