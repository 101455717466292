import { GET_FAVORITES, SAVE_FAVORITO, TOOGLE_MODAL_FAVORITO, LOADING_FAVORITO, CLEAN_STATE } from './types';

export const getFavorites = payload => {
  return {
  type: GET_FAVORITES,
  payload,
}}

export const saveFavorito = payload => ({
  type: SAVE_FAVORITO,
  payload,
});

// Toogle Modal Favorito
export const toogleModalFavorito = payload => ({
  type: TOOGLE_MODAL_FAVORITO,
  payload,
});

// Loading Favorito
export const loadingFavorito = payload => ({
  type: LOADING_FAVORITO,
  payload,
});

// Clean State
export const cleanState = () => ({
  type: CLEAN_STATE,
});
