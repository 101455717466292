import types from './types';

const initialState = {
  email: '',
  loading: '',
  status: false,
  message: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case types.SET_STATUS_FORGOT:
      return {
        ...state,
        status: action.payload,
      };
    case types.SET_MESSAGE_FORGOT:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
}
