import React from 'react';
// reactstrap components
import { Input, Row, Col } from 'reactstrap';
// import Select from 'react-select';
import Select from 'react-dropdown-select';

import styled from 'styled-components';

import { datos } from 'data/download.js';

class Fuente extends React.Component {
  constructor(props) {
    super(props);
    const { tipoFuente, valorFuente } = this.props;
    this.state = {
      tipoFuente,
      valorFuente,
    };

    this.handleChangeExcluirCerosONulos = this.handleChangeExcluirCerosONulos.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { tipoFuente, valorFuente } = this.props;
    if (prevProps.valorFuente !== valorFuente || prevProps.tipoFuente !== tipoFuente) {
      const vFuente = valorFuente.map(v => ({ label: v, value: v}));
      this.setState({ valorFuente: vFuente, tipoFuente});
    }
  }

  handleTipoFuente = v => {
    this.setState({ [v.target.name]: v.target.value });
    this.props.handleFuente(v.target.name, v.target.value);
  };

  handleValorFuente = v => {
    this.setState({ valorFuente: v || [] });
    this.props.handleFuente(
      'valorFuente',
      v ? v.map(v => v.value) : [],
    );
  };

  customContentRenderer = ({ props, state, methods }) => (
    <div style={{ cursor: 'pointer' }}>
      {state.values.length === 0 ? (
        <>Seleccione una o varias opciones</>
      ) : (
        <>
          Seleccionados {state.values.length} de {props.options.length}
        </>
      )}
    </div>
  );

  customDropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search, 'i');
    return (
      <div>
        <SearchAndToggle>
          <Buttons>
            <div>Buscar y seleccionar:</div>
            {state.values.length === props.options.length ? (
              <Button className="clear" onClick={methods.clearAll}>
                Borrar todo
              </Button>
            ) : (
              <Button onClick={methods.selectAll}>Seleccionar todo</Button>
            )}
          </Buttons>
          <input
            type="text"
            value={state.search}
            onChange={methods.setSearch}
            placeholder="Buscar..."
          />
        </SearchAndToggle>
        <Items>
          {props.options
            .filter(item => regexp.test(item[props.searchBy] || item.label))
            .map(option => {
              return (
                <Item
                  key={option.value}
                  onClick={() => methods.addItem(option)}>
                  <input
                    type="checkbox"
                    onChange={() => methods.addItem(option)}
                    checked={!!state.values.find(o => o.value === option.value)}
                  />
                  <ItemLabel>{option.label}</ItemLabel>
                </Item>
              );
            })}
        </Items>
      </div>
    );
  };

  handleChangeExcluirCerosONulos(e) {
    const val = e.target.checked;

    this.props.onChangeExcluirCerosONulos(val);
  };

  render() {
    const { tipoFuente, clientId } = this.props;
    const options = tipoFuente ? datos(clientId).b2b : [];

    const options2 = options.filter(o => {
      if (this.props.tipoPeriodo !== 'days' && clientId === 31) {
        return (o.value !== 'stock' && o.value !== 'volumenStock')
      }
      return o
    })

    return (
      <>
        <Row>
          <Col md="2">
            {clientId === 31
              ? <h2 className="mb-4">2.- Fuente</h2>
              : <h2 className="mb-4">1.- Fuente</h2>
            }
          </Col>
          <Col md="10">
            <Row className="mb-3">
              <Col md="5"
                style={
                  clientId ===31 && this.props.tipoPeriodo === ''
                    ? { pointerEvents: 'none', opacity: '0.4' }
                    : {}
                }
              >
                <Input
                  name="tipoFuente"
                  type="select"
                  value={this.state.tipoFuente}
                  onChange={v => this.handleTipoFuente(v)}>
                  {this.state.tipoFuente === '' && (
                    <option>Seleccione una opción</option>
                  )}
                  {Object.keys(datos(clientId)).map((value, key) => {
                    return (
                      <option key={key} label={value}>
                        {value}
                      </option>
                    );
                  })}
                </Input>
              </Col>
              <Col
                md="5"
                style={
                  this.state.tipoFuente === ''
                    ? { pointerEvents: 'none', opacity: '0.4' }
                    : {}
                }>
                <Row style={{ flexDirection: 'column' }}>
                  <Col>
                    <Select
                      placeholder="Seleccione una o varias opciones"
                      disabled={this.state.tipoFuente === ''}
                      multi
                      searchable
                      options={options2}
                      values={this.state.valorFuente}
                      onChange={v => this.handleValorFuente(v)}
                      contentRenderer={this.customContentRenderer}
                      dropdownRenderer={this.customDropdownRenderer}
                    />
                  </Col>
                  <Col style={{ padding: 24 }}>
                    <Row className="pl-3">
                      <label className="form-control-label mr-3" htmlFor="sumarPeriodo">
                        Excluir ceros o nulos:
                      </label>

                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name="sumarPeriodo"
                          defaultChecked={this.props.excluirCerosONulos}
                          checked={this.props.excluirCerosONulos}
                          onChange={(e) => this.handleChangeExcluirCerosONulos(e)}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Si"
                        />
                      </label>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
      </>
    );
  }
}

export default Fuente;

const SearchAndToggle = styled.div`
  display: flex;
  flex-direction: column;

  input {
    margin: 10px 10px 0;
    line-height: 30px;
    padding: 0px 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    :focus {
      outline: none;
      border: 1px solid #6B9DD1;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  & div {
    margin: 10px 0 0 10px;
    font-weight: 600;
  }
`;

const Button = styled.button`
  background: none;
  border: 1px solid #555;
  color: #555;
  border-radius: 3px;
  margin: 10px 10px 0;
  padding: 3px 5px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;

  &.clear {
    color: tomato;
    border: 1px solid tomato;
  }

  :hover {
    border: 1px solid #6B9DD1;
    color: #6B9DD1;
  }
`;

const Items = styled.div`
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
`;

const Item = styled.div`
  display: flex;
  margin: 10px;
  align-items: baseline;
`;

const ItemLabel = styled.div`
  margin: 5px 10px;
`;
