import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';

class AuthHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-lighter py-7 py-lg-8 pt-lg-9 mt--7">
          <Container>
            <div className="header-body text-center mb-5">
              <Row className="justify-content-center">
                <Col className="px-3" lg="6" md="8" xl="5">
                  <img
                    className="mb-5"
                    alt={this.props.logo.imgAlt}
                    src={this.props.logo.imgSrc}
                    style={{
                      maxWidth: '100%',
                    }}
                  />
                  {this.props.title ? (
                    <h1 className="text">{this.props.title}</h1>
                  ) : null}
                  {this.props.lead ? (
                    <p className="text-lead text">{this.props.lead}</p>
                  ) : null}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;
