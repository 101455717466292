import axios from 'axios';

import { url } from '../../../config/connections';

import * as actions from './actions';

const getDataInfo = () => async (dispatch, getState) => {
  dispatch(actions.setLoading(true))
  axios
    .get(`${url()}/b2b/status`)
    .then(res => {
      dispatch(actions.setDataInfo(res.data))
      dispatch(actions.setLoading(false))
    })
    .catch(err => {
      console.log('err', err.message);
      dispatch(actions.setLoading(false))
    });
};

const cleanState = () => async (dispatch, getState) => {
  dispatch(actions.cleanState())
}

export default {
  getDataInfo,
  cleanState
};
