import React from 'react';
/* import PropTypes from 'prop-types'; */
// redux
import { bindActionCreators } from 'redux';
// react-redux connect
import { connect } from 'react-redux';
// reactstrap components
import { Container, Row, Col, Progress } from 'reactstrap';
// core components
import AuthHeader from 'components/Headers/AuthHeader.jsx';

import * as actionsReports from '../../state/ducks/reports';
import * as actionsDownloads from '../../state/ducks/downloads';
import * as actionsFavorites from '../../state/ducks/favorites';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
    this.reportsRef = React.createRef(Number(0));
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { actions, user } = this.props;
    const { clientId } = user;
    if (clientId === 56 || clientId === 31 || clientId === 15 || clientId === 60 || clientId === 62) {
      actions.getAperturas();
      actions.getPeriodos();
      actions.getFavorites();
    }
    actions.getReports();
  }

  shouldComponentUpdate(nextProps) {
    const aperturas = Object.entries(nextProps.download.aperturas).length > 0;
    const periodos = nextProps.download.periodos.length > 0;
    const reports = nextProps.report.reports.length > 0;
    const { clientId } = this.props.user;

    if (nextProps.progress !== this.props.progress) return true;

    if ((clientId === 56 || clientId === 31 || clientId === 15) &&
      aperturas &&
      periodos &&
      reports
    ) return true;

    if ((clientId !== 56 && clientId !== 31 && clientId !== 15) && reports) return true;

    return false;
  }

  componentDidUpdate(prevProps) {
    const { history, report, user, download } = this.props;
    const { clientId } = user;

    const aperturas = Object.entries(download.aperturas).length > 0;
    const periodos = download.periodos.length > 0;
    const reports = report.reports.length > 0;

    if ((clientId === 56 || clientId === 31 || clientId === 15 || clientId === 60 || clientId === 62) &&
      aperturas &&
      periodos &&
      reports
    ) {
      history.push(`/dashboard/reporte/${report.reports[0].name.toLowerCase()}`);
    } 

    if ((clientId !== 56 && clientId !== 31 && clientId !== 15 && clientId !== 60 && clientId !== 62) && reports) {
      history.push(`/dashboard/reporte/${report.reports[0].name.toLowerCase()}`);
    }

    if (prevProps.errors) {
      this.setState({ errors: prevProps.errors });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.type]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const user = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.actions.loginUser(user);
  }

  render() {
    return (
      <>
        <AuthHeader
          title="Estamos cargando la información"
          lead=""
          logo={{
            innerLink: '/',
            imgSrc: require('assets/img/brand/logo-cademsmart.png'),
            imgAlt: '...',
          }}
        />
        <Container className="mt--8 pb-9">
          <Row className="justify-content-center">
            <Col lg="10" md="7">
              <Progress max="100" value={this.props.progress} color="primary" />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { ...actionsDownloads.duckOperations, ...actionsReports.duckOperations, ...actionsFavorites.duckOperations },
    dispatch,
  ),
  dispatch,
});

const mapStateToProps = state => ({
  user: state.authReducer.user,
  download: state.downloadReducers,
  report: state.reportsReducers,
  progress: state.splashReducers.progress
});

/* Login.propTypes = {}; */

export default connect(mapStateToProps, mapDispatchToProps)(Login);
