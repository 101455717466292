import types from './types';

// Get Reports
export const setProgress = payload => ({
  type: types.SET_PROGRESS,
  payload,
});

// Clean State
export const cleanState = () => ({
  type: types.CLEAN_STATE,
});
