import { GET_FAVORITES, SAVE_FAVORITO, TOOGLE_MODAL_FAVORITO, LOADING_FAVORITO, CLEAN_STATE } from './types';

const defaultState = {
  favorites: [],
  modal: false,
  loading: false,
  message: '',
  save: false,
}

export default function(state=defaultState, action) {
  switch (action.type) {
    case GET_FAVORITES:
      return {
        ...state,
        favorites: action.payload,
      }
    case SAVE_FAVORITO:
      return {
        ...state,
        ...action.payload,
      };
    case TOOGLE_MODAL_FAVORITO:
      return {
        ...state,
        ...action.payload,
      };
    case LOADING_FAVORITO:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAN_STATE:
      return defaultState;
    default:
      return state;
  }
}
