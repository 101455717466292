import React from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Input,
  Row,
  Col,
  Modal,
  Card,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  Table,
  Badge,
} from 'reactstrap';
import { BeatLoader, ClipLoader } from 'react-spinners';
import moment from 'moment';
import io from 'socket.io-client';

import { url } from '../../config/connections';

import { tiposExportacion } from 'data/download.js';
import Axios from 'axios';

class Descarga extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      formModal: false,
      updatingPortal: false,
      loadingRecentDownloads: false,
      now: Date.now(),
      lastUpdate: Date.now(),
      recentDownloads: [],
      intervalId: null,
    };

    this.downloadAdded = this.downloadAdded.bind(this);
    this.loadRecentDownloads = this.loadRecentDownloads.bind(this);
    this.handleChangeExcluirCerosONulos = this.handleChangeExcluirCerosONulos.bind(this);

    this.client = io(`${url()}/portal-descargas`, {
      query: {
        token: this.props.token,
      },
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
    });

    this.client.on('updating-portal-status', (status) => {
      this.setState({
        updatingPortal: status,
      });
    });

    this.client.on('job-updated', (data) => {
      this.loadRecentDownloads();
    });

    this.client.connect();
  }

  handleChangeExcluirCerosONulos(e) {
    const val = e.target.checked;

    this.props.onChangeExcluirCerosONulos(val);
  };

  downloadAdded(job_id) {
    if (this.client !== null) {
      setTimeout(() => {
        this.client.emit('download-added', { job_id });
      }, 500);
    }

    this.loadRecentDownloads();
  }

  componentDidMount() {
    this.loadRecentDownloads();

    this.setState({
      intervalId: setInterval(() => {
        this.setState({
          now: Date.now(),
        });
      }, 1000),
    });
  }

  loadRecentDownloads() {
    this.setState({
      loadingRecentDownloads: false,
    });

    this.setState({
      lastUpdate: Date.now(),
    });

    Axios.post(`${url()}/download/ultimas_descargas`).then(response => {
      const recentDownloads = response.data.data.recent_downloads;

      this.setState({
        recentDownloads: recentDownloads,
      });

      if (this.client !== null) {
        recentDownloads.forEach(download => {
          this.client.emit('download-added', { job_id: download.job_id });
        })
      }
    }).catch(error => {
      console.log(error);
      this.setState({
        recentDownloads: [],
      });
    }).finally(() => {
      this.setState({
        loadingRecentDownloads: false,
      });
    })
  }

  componentWillUnmount() {
    if (this.client !== null) {
      this.client.close();
    }

    if (this.state.intervalId !== null) {
      clearInterval(this.state.intervalId);
    }
  }

  toggleModal = () => {
    const { toogleModalFavorito } = this.props;
    this.setState({ formModal: !this.state.formModal });
    toogleModalFavorito(true);
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') e.preventDefault();
  }

  handleSubmitFavorito = e => {
    e.preventDefault();

    if (this.state.name === '') {
      alert('Debe agregar un nombre');
      return;
    }

    this.props.handleFavorito(this.state.name);
  };

  toggleModalQueue(type) {
    switch (type) {
      case 'success':
        this.props.setShowSuccessQueue(!this.props.download.showSuccessQueue);
        break;
      case 'failed':
        this.props.setShowFailedQueue(!this.props.download.showFailedQueue);
        break;
      default:
        return;
    }
  }

  getDownload(download) {
    if (download.status_code === 2) {
      return (<tr key={download.id}>
        <td>{ download.id }</td>
        <td>
          <Badge title={"Haz click en \"Descargar\""} color="success">{ download.status }</Badge>
        </td>
        <td>
          <Badge href={download.download_link} target="_blank" title="Descargar archivo solicitado" color="success">Descargar</Badge>
        </td>
        <td>
          { moment(download.created_at).format('YYYY-MM-DD HH:mm:ss') }
        </td>
      </tr>)
    }

    if ([0, 1].includes(download.status_code)) {
      return (<tr key={download.id}>
        <td>{ download.id }</td>
        <td>
          <Badge color={download.status_code === 0 ? "primary" : "warning"}>{ download.status }</Badge>
        </td>
        <td>
          <p title="Generando archivo...">...</p>
        </td>
        <td>
          { moment(download.created_at).format('YYYY-MM-DD HH:mm:ss') }
        </td>
      </tr>)
    }

    return (<tr key={download.id}>
      <td>{ download.id }</td>
      <td>
        <Badge color="danger">{ download.status }</Badge>
      </td>
      <td>
        <Badge title="Intente con menos dias" color="danger">Error generando archivo</Badge>
      </td>
      <td>
        { moment(download.created_at).format('YYYY-MM-DD HH:mm:ss') }
      </td>
    </tr>)
  }

  showRecentDownloads(loading, empty, downloads) {
    return <Table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Estado</th>
          <th>Link descarga</th>
          <th>Fecha de creacion</th>
        </tr>
      </thead>
      <tbody>
        { this.state.loadingRecentDownloads === true && loading }
        { this.state.loadingRecentDownloads === false && this.state.recentDownloads.length === 0 && empty }
        { this.state.loadingRecentDownloads === false && this.state.recentDownloads.length > 0 && downloads }
      </tbody>
    </Table>
  }

  render() {
    const {
      tipoDescarga,
      handleChange,
      handleClick,
      inputList,
      loading,
      favoritosLoading,
    } = this.props;

    return (
      <>
        <Row>
          <Col md="2">
            <h2 className="mb-4">4.- Descargar archivo</h2>
          </Col>
          <Col md="10">
            <Row>
              <Col xs="12" md="5">
                <Row>
                  <Col xs="1" md="1">
                    <i
                      className="fas fa-file-excel display-4"
                      style={{
                        color: '#1D6F42',
                      }}
                    />
                  </Col>
                  <Col
                    xs="11"
                    md="11"
                    style={
                      inputList[0].tipoApertura === ''
                        ? { pointerEvents: 'none', opacity: '0.4' }
                        : {}
                    }>
                    <Input
                      name="tipoDescarga"
                      type="select"
                      value={tipoDescarga}
                      onChange={v => handleChange(v)}>
                      {tipoDescarga === '' && (
                        <option>Seleccione una opción</option>
                      )}
                      {tiposExportacion.map((item, key) => (
                        <option key={key} label={item.label}>
                          {item.value}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Col>

              <Col
                md="6"
                style={
                  tipoDescarga === ''
                    ? { pointerEvents: 'none', opacity: '0.4', marginLeft: 20 }
                    : { marginLeft: 20 }
                }>
                { this.props.valorFuente.length === 0 &&
                  <Row>
                    <p style={{color: 'red', fontSize: '12px'}}>Seleccione una columna de fuente</p>
                  </Row>
                }
                { (this.props.inicio.length === 0 && this.props.fin.length === 0) &&
                  <Row>
                    <p style={{color: 'red', fontSize: '12px'}}>Seleccione un periodo de inicio y fin</p>
                  </Row>
                }
                { (this.props.inicio.length === 0 && this.props.fin.length > 0) &&
                  <Row>
                    <p style={{color: 'red', fontSize: '12px'}}>Seleccione un perido de inicio</p>
                  </Row>
                }
                { (this.props.inicio.length > 0 && this.props.fin.length === 0) &&
                  <Row>
                    <p style={{color: 'red', fontSize: '12px'}}>Seleccione un perido de fin</p>
                  </Row>
                }
                <Row>
                  <Row>
                    <Col>
                      <Button
                        className="btn-icon"
                        style={
                          loading ? { pointerEvents: 'none', opacity: '0.4' } : {}
                        }
                        color="primary"
                        type="button"
                        onClick={() => handleClick(this.state.updatingPortal, this.downloadAdded)}
                        disabled={this.props.valorFuente.length === 0 || this.props.inicio.length === 0 || this.props.fin.length === 0}>
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-cloud-download-95" />
                        </span>
                        <span className="btn-inner--text">Descargar</span>
                      </Button>
                    </Col>
                  </Row>
                  {loading && (
                    <BeatLoader
                      css={{
                        marginLeft: 20,
                        marginRight: 20,
                        alignSelf: 'center',
                      }}
                      size={13}
                      color={'#6B9DD1'}
                    />
                  )}

                  <Button
                    className="btn-icon"
                    color="primary"
                    type="button"
                    outline
                    onClick={() => this.toggleModal('formModal')}>
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-favourite-28" />
                    </span>
                    <span className="btn-inner--text">Favorito</span>
                    <Modal
                      className="modal-dialog-centered"
                      size="sm"
                      isOpen={this.props.modal}
                      toggle={() => this.toggleModal('formModal')}>
                      <div className="modal-body p-0">
                        <Card className="bg-secondary border-0 mb-0">
                          <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                              <h4>Ingresa un nombre para tu favorito</h4>
                            </div>
                            <Form role="form">
                              <FormGroup
                                className={classnames('mb-3', {
                                  focused: this.state.focusedEmail,
                                })}>
                                <InputGroup className="input-group-merge input-group-alternative">
                                  <Input
                                    name="name"
                                    placeholder="Nombre"
                                    type="text"
                                    onFocus={() =>
                                      this.setState({ focusedInput: true })
                                    }
                                    onBlur={() =>
                                      this.setState({ focusedInput: false })
                                    }
                                    onChange={this.handleChange}
                                    onKeyPress={this.handleKeyPress}
                                  />
                                </InputGroup>
                              </FormGroup>
                              <div
                                className="text-center"
                                style={
                                  favoritosLoading
                                    ? {
                                        pointerEvents: 'none',
                                        opacity: '0.4',
                                      }
                                    : {}
                                }>
                                <Button
                                  className="my-4"
                                  color="primary"
                                  type="button"
                                  onClick={this.handleSubmitFavorito}>
                                  <span className="btn-inner--icon mr-1">
                                    <i className="ni ni-favourite-28" />
                                  </span>
                                  Guardar Favorito
                                  {favoritosLoading && (
                                    <ClipLoader
                                      css={{
                                        marginLeft: 20,
                                      }}
                                      size={15}
                                      color={'#FFF'}
                                    />
                                  )}
                                </Button>
                                <p>{this.props.message}</p>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </div>
                    </Modal>
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md="2"></Col>
          <Col md="10">
            <div style={{ paddingTop: '32px'}}>
              <Row>
                <Col md="8">
                  <p>
                    Ultimas descargas:
                  </p>
                </Col>
                <Col md="4">
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {moment(this.state.lastUpdate).from(this.state.now)}
                    <Button color="link" onClick={() => this.loadRecentDownloads()}>
                      <i className="fas fa-redo"></i>
                    </Button>
                  </div>
                </Col>
              </Row>
              { this.showRecentDownloads(
                <tr>
                  <td colSpan="4">
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <ClipLoader></ClipLoader>
                    </div>
                  </td>
                </tr>,
                <tr>
                  <td>Sin descargas para mostrar</td>
                </tr>,
                this.state.recentDownloads.sort((a, b) => a.id - b.id).reverse().map(download => this.getDownload(download))
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default Descarga;
