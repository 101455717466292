/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// react library for routing
import { Route, Switch } from 'react-router-dom';
// redux
import { bindActionCreators } from 'redux';
// react-redux connect
import { connect } from 'react-redux';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import AdminFooter from 'components/Footers/AdminFooter.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import News from 'components/News/News.jsx';
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

import * as actions from '../state/ducks/splash';

import routes from 'routes.js';

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidenavOpen: true,
    };
  }

  componentDidMount() {
    const { report, download, user, actions } = this.props;
    const { reports } = report;
    const { aperturas, periodos } = download;
    const { clientId } = user;

    actions.cleanState(0);

    if (reports.length === 0) {
      this.props.history.push('/splash');
    }

    if ((clientId === 56 || clientId === 15 || clientId === 31 || clientId === 60) &&
      (Object.entries(aperturas).length === 0 || periodos.length === 0)) {
      this.props.history.push('/splash');
    }
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      // eslint-disable-next-line react/no-string-refs
      this.refs.mainContent.scrollTop = 0;
    }

    if (this.props.changePassword.message === 'Contraseña modificada') {
      this.notify("success")
    }
  }

  getRoutes = routes =>
    routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/dashboard') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });

  getBrandText = path => {
    const { location } = this.props;
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };

  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    const { sidenavOpen } = this.state;
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    this.setState({
      sidenavOpen: !sidenavOpen,
    });
  };

  getNavbarTheme = () =>
    // eslint-disable-next-line react/destructuring-assignment
    this.props.location.pathname.indexOf('admin/alternative-dashboard') === -1
      ? 'dark'
      : 'light';

  notify = type => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Contraseña cambiada con éxito
          </span>
          <span data-notify="message">
            La próxima vez que inicies sesión, debes hacerlo con tu nueva contraseña
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 10
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    const { location } = this.props;
    const { sidenavOpen } = this.state;

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={sidenavOpen}
          logo={{
            innerLink: '/',
            imgSrc: require('assets/img/brand/logo-cademsmart.png'),
            imgAlt: '...',
          }}
        />
        <div
          className="main-content"
          // eslint-disable-next-line react/no-string-refs
          ref="mainContent"
          onClick={this.closeSidenav}>
          <News></News>
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={this.getBrandText(location.pathname)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <AdminFooter />
        </div>
        {sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions.duckOperations, dispatch),
  dispatch,
});

const mapStateToProps = state => ({
  user: state.authReducer.user,
  changePassword: state.authReducer.changePassword,
  report: state.reportsReducers,
  download: state.downloadReducers,
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);


