import types from './types';

const initialState = {
  progress: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case types.CLEAN_STATE:
      return initialState;
    default:
      return state;
  }
}
