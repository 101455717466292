const SET_APERTURAS = 'SmartWeb/download/SET_APERTURAS';
const SET_PERIODOS = 'SmartWeb/download/SET_PERIODOS';
const SET_LOADING = 'SmartWeb/download/SET_LOADING';
const CLEAN_STATE = 'SmartWeb/download/CLEAN_STATE';

export default {
  SET_APERTURAS,
  SET_PERIODOS,
  SET_LOADING,
  CLEAN_STATE
};
