import types from './types';

import isEmpty from '../../../validations/isEmpty';

const initialState = {
  isAuthenticated: false,
  user: {},
  token: '',
  showNews: false,
  isLoading: false,
  changePassword: {
    message: '',
    loading: false
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_LOADING_FORM:
      return {
        ...state,
        loadingForm: action.payload,
      };
    case types.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case types.SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case types.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.CHANGE_PASSWORD_MESSAGE:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          message: action.payload
        },
      };
    case types.CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          loading: action.payload
        },
      };
    case types.SET_SHOW_NEWS:
      return {
        ...state,
        showNews: action.payload,
      };
    default:
      return state;
  }
}
