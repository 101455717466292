import types from './types';

const initialState = {
  b2bData: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_DATA_INFO:
      return {
        ...state,
        b2bData: action.payload,
      };
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.CLEAN_STATE:
      return initialState;
    default:
      return state;
  }
}
