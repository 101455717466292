import React from 'react';
// redux
import { bindActionCreators } from 'redux';
// react-redux connect
import { connect } from 'react-redux';
// nodejs library that concatenates classes
import classnames from 'classnames';
// react library for routing
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { ClipLoader } from 'react-spinners';
// core components
import AuthHeader from 'components/Headers/AuthHeader.jsx';

import * as actions from '../../state/ducks/forgot';

class Forgot extends React.Component {
  state = {
    email: ''
  };

  handleChangeEmail = (e) => {
    const { actions } = this.props;
    this.setState({ [e.target.name]: e.target.value })

    actions.setEmail(e.target.value)
  }

  handleSubmitForgot = () => {
    const { actions } = this.props;

    actions.forgotPassword();
  }

  render() {
    return (
      <>
        <AuthHeader
          title="Recuperar Contraseña"
          lead=""
          logo={{
            innerLink: '/',
            imgSrc: require('assets/img/brand/logo-cademsmart.png'),
            imgAlt: '...',
          }}
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-muted text-center mt-2 mb-5">
                    <small>
                      Ingrese su email registrado para resetear su contraseña
                    </small>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames('mb-3', {
                        focused: this.state.focusedEmail,
                      })}>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          name="email"
                          onChange={(v) => this.handleChangeEmail(v)}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button className="my-4" color="info" type="button" onClick={this.handleSubmitForgot}>
                        Reestablecer Contraseña
                        {this.props.forgot.status && (
                          <ClipLoader
                            css={{
                              marginLeft: 20,
                            }}
                            size={15}
                            color={'#FFF'}
                          />
                        )}
                      </Button>
                      <p>{this.props.forgot.message}</p>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <Link className="text-white" to="/">
                    <small>Volver al inicio</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions.duckOperations, dispatch),
  dispatch,
});

const mapStateToProps = state => ({
  forgot: state.forgotReducers,
});

/* Forgot.propTypes = {}; */

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
