import React from 'react';
/* import PropTypes from 'prop-types'; */
// redux
import { bindActionCreators } from 'redux';
// react-redux connect
import { connect } from 'react-redux';
// reactstrap components
import {
  UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { DotLoader } from 'react-spinners';
import moment from 'moment';
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.jsx';

// download components
import FuenteComponent from 'components/Downloads/Fuente.jsx';
import PeriodoComponent from 'components/Downloads/Periodo.jsx';
import AperturaComponent from 'components/Downloads/Apertura.jsx';
import DescargaComponent from 'components/Downloads/Descarga.jsx';

import * as downloadActions from '../../state/ducks/downloads';
import * as favoritesActions from '../../state/ducks/favorites';
import * as b2bActions from '../../state/ducks/b2b';

class Favoritos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFavorite: {},
      showModal: false,
    };
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnClickConfirm = this.handleOnClickConfirm.bind(this);
    this.handleOnClickCancel = this.handleOnClickCancel.bind(this);
  }

  handleOnClick(favorito) {
    this.setState({ selectedFavorite: favorito, showModal: true });
  }

  handleOnClickConfirm(e) {
    e.preventDefault();
    this.setState({ showModal: false });
    this.props.onClick(true, this.state.selectedFavorite);
  }

  handleOnClickCancel(e) {
    e.preventDefault();
    this.setState({ showModal: false });
    this.props.onClick(false, this.state.selectedFavorite);
  }

  handleOnClickDelete(favorito) {
    this.props.actions.deleteFavorito(favorito);
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Favoritos
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={this.props.handleOnClose}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-body">
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>NOMBRE</th>
                <th>CREADO</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {this.props.favoritos.map((favorito, idx) => (
                <tr key={favorito._id}>
                  <th scope="row">{idx + 1}</th>
                  <td className="table-user">{favorito.name}</td>
                  <td>
                    <span className="text-muted">
                      {moment(favorito.createdAt).format('DD-MM-YYYY')}
                    </span>
                  </td>
                  <td className="table-actions">
                    <i
                      className="fas fa-cloud-download-alt"
                      id="cargarFavorito"
                      onClick={() => this.handleOnClick(favorito)}
                      style={{
                        marginRight: 15,
                      }}
                    />
                    <UncontrolledTooltip delay={0} target="cargarFavorito">
                      Cargar favorito
                    </UncontrolledTooltip>
                    <i
                      className="fas fa-trash"
                      id="eliminarFavorito"
                      onClick={() => this.handleOnClickDelete(favorito)}
                    />
                    <UncontrolledTooltip delay={0} target="eliminarFavorito">
                      Eliminar favorito
                    </UncontrolledTooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="modal-footer">
            <Button
              className="ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={this.props.handleOnClose}>
              Cerrar
            </Button>
          </div>
        </div>
        <Modal isOpen={this.state.showModal}>
          <ModalHeader> CONFIRMAR CARGAR DATOS </ModalHeader>
          <ModalBody>
            Esta seguro que desea cargar los datos de{' '}
            {this.state.selectedFavorite.name}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              value="accept"
              onClick={this.handleOnClickConfirm}>
              SI
            </Button>{' '}
            <Button
              color="secondary"
              value="cancel"
              onClick={this.handleOnClickCancel}>
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </Modal>
    );
  }
}

Favoritos.defaultProps = {
  isOpen: false,
  onClick: () => console.log('hi'),
};

class DownloadPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tipoFuente: '',
      valorFuente: [],
      tipoPeriodo: '',
      sumarPeriodo: false,
      excluirCerosONulos: false,
      inicio: '',
      fin: '',
      inputList: [{ tipoApertura: '', valorApertura: [] }],
      tipoDescarga: '',
      isOpen: false,
      aperturas: this.props.download.aperturas,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMultiple = this.handleChangeMultiple.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleFavorito = this.handleFavorito.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChangeValorFuente = this.onChangeValorFuente.bind(this);
    this.onChangeExcluirCerosONulos = this.onChangeExcluirCerosONulos.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  handleChangeMultiple(value, name) {
    this.setState({ [name]: value });
  }

  handleFuente = (type, value) => {
    this.setState({ [type]: value });
  };

  handleDate = (type, value) => {
    this.setState({ [type]: value });
    if (type === 'tipoPeriodo') {
      this.setState({ inicio: '', fin: '' })
    }
    if (type === 'inicio') {
      this.setState({ fin: '' })
    }
  };

  /* Funciones Aperturas */
  onAddApertura = () => {
    // this.setState(prevState => ({
    //   inputList: [
    //     ...prevState.inputList,
    //     { tipoApertura: '', valorApertura: [] },
    //   ],
    // }));
    this.setState({
      inputList: [
        ...this.state.inputList,
        { tipoApertura: '', valorApertura: [] },
      ],
    });
  };

  onRemoveApertura = (item, index) => {
    const { inputList } = this.state;
    if (inputList.length === 1) return;
    this.setState({
      inputList: [...this.state.inputList.filter(value =>
        value.tipoApertura !== item.tipoApertura)]
    });
  };

  handleChangeApertura = (name, value, i) => {
    let inputList = [...this.state.inputList];
    inputList[i] = { ...inputList[i], [name]: value };
    this.setState({ inputList });
  };
  /* Fin Funciones Aperturas */

  /* Boton descargar */
  handleClick = (updatingPortal, cb) => {
    const { actions } = this.props;

    actions.downloadData(this.state, (job_id) => {
      if (!this.state.name) {
        this.setState({
          tipoFuente: '',
          valorFuente: [],
          inicio: '',
          fin: '',
          excluirCerosONulos: false,
          inputList: [{ tipoApertura: '', valorApertura: [] }],
          tipoDescarga: '',
        });
      }

      cb(job_id);
    });
  };
  /* Fin Boton descargar */

  handleFavorito = name => {
    const { actions, user } = this.props;
    const data = {
      ...this.state,
      name,
      clientId: user.clientId,
      email: user.email,
    };
    setTimeout(() => {
      this.setState({ name })
    }, 3500);
    actions.saveFavorito(data);
  };

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  onClick(confirm, item) {
    if (confirm) {
      this.setState({
        tipoFuente: item.tipoFuente,
        valorFuente: item.valorFuente,
        tipoPeriodo: item.tipoPeriodo,
        sumarPeriodo: item.sumarPeriodo,
        inicio: item.inicio,
        fin: item.fin,
        inputList: [...item.inputList],
        tipoDescarga: item.tipoDescarga,
        isOpen: !this.state.isOpen,
        name: item.name,
      });
    }
  }

  onChangeApertura = (name, value, i) => {
    let inputList = [...this.state.inputList];
    inputList[i] = { ...inputList[i], [name]: value };
    this.setState({ inputList });
  }

  onChangeValorFuente = e => {
    console.log('e?', e);
  };

  handleOnClose = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClickInfo(e) {
    e.preventDefault();
    this.props.actions.getDataInfo()
  }

  onChangeExcluirCerosONulos(val) {
    this.setState({
      excluirCerosONulos: val,
    });
  }

  render() {
    const { b2b, favoritos, download, user, actions, token } = this.props;

    if (Object.keys(favoritos).length) {
      return (
        <>
          <SimpleHeader
            name="Descargas de datos"
            parentName=""
            iconClassName="fas fa-home"
          />
          <Container className="mt--6" fluid>
            <Card className="mb-4 pb-4">
              <CardHeader>
                <Row>
                  <Col md={6}>
                    <Row className="pl-3 align-items-center">
                      <h3 className="mb-0">Selección de datos</h3>
                      <UncontrolledDropdown nav>
                        <DropdownToggle className="nav-link" tag="a" onClick={e => this.handleClickInfo(e)}>
                          <Button
                            className="btn-icon-only rounded-circle"
                            outline
                            color="primary"
                            type="button"
                          >
                            <i className="fas fa-bell" />
                          </Button>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden">
                          <div className="px-3 py-3">
                            <h6 className="text-sm text-muted m-0">
                              Información de <strong className="text-info">Data B2B</strong>
                            </h6>
                          </div>
                          <ListGroup flush>
                            { b2b.loading
                            ? (
                              <ListGroupItem
                                className="list-group-item-action">
                                <Row className="justify-content-center align-items-center">
                                  <DotLoader
                                    size={50}
                                    color={'#6B9DD1'}
                                  />
                                </Row>
                              </ListGroupItem>
                            ) : (
                              b2b.b2bData.map(i => {
                                return (
                                  <ListGroupItem
                                    key={i.retail}
                                    className="list-group-item-action">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          alt="..."
                                          src={require(`assets/img/retails/${i.retail.toLowerCase()}.png`)}
                                        />
                                      </Col>
                                      <div className="col ml--2">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>
                                            <h4 className="mb-0 text-sm">{i.retail}</h4>
                                          </div>
                                        </div>
                                        <p className="text-sm mb-0">
                                          Última fecha: <strong className="text-info">{moment(i.fecha).utc().format('YYYY-MM-DD')}</strong>
                                        </p>
                                      </div>
                                    </Row>
                                  </ListGroupItem>
                                )
                              })
                            )
                          }
                          </ListGroup>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="justify-content-md-center">
                      <Col md={6}>
                        <Button
                          className="btn-icon"
                          color="primary"
                          type="button"
                          outline
                          onClick={this.toggle}>
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-favourite-28" />
                          </span>
                          <span className="btn-inner--text">
                            Seleccionar Favorito
                          </span>
                        </Button>
                      </Col>
                      <Col md={6}>
                        {this.state.name && (
                          <h4>
                            Favorito cargado: <i>{this.state.name}</i>
                          </h4>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Favoritos
                  favoritos={favoritos.favorites}
                  onClick={this.onClick}
                  isOpen={this.state.isOpen}
                  handleOnClose={this.handleOnClose}
                  actions={actions}
                />
              </CardHeader>

              <CardBody>
                {user.clientId === 31 ? (<>
                  <PeriodoComponent
                    // {...this.state}
                    tipoPeriodo={this.state.tipoPeriodo}
                    handleChange={this.handleChange}
                    inicio={this.state.inicio}
                    fin={this.state.fin}
                    tipoFuente={this.state.tipoFuente}
                    periodos={this.props.download.periodos}
                    handleDate={this.handleDate}
                    onRef={ref => (this.child = ref)}
                    clientId={user.clientId}
                  />

                  <FuenteComponent
                    tipoFuente={this.state.tipoFuente}
                    valorFuente={this.state.valorFuente}
                    clientId={user.clientId}
                    excluirCerosONulos={this.state.excluirCerosONulos}
                    handleFuente={this.handleFuente}
                    handleChange={this.onChangeValorFuente}
                    handleChangeMultiple={this.handleChangeMultiple}
                    onChangeExcluirCerosONulos={this.onChangeExcluirCerosONulos}
                    tipoPeriodo={this.state.tipoPeriodo}
                  />

                </>) : (<>
                  <FuenteComponent
                    tipoFuente={this.state.tipoFuente}
                    valorFuente={this.state.valorFuente}
                    clientId={user.clientId}
                    excluirCerosONulos={this.state.excluirCerosONulos}
                    handleFuente={this.handleFuente}
                    handleChange={this.onChangeValorFuente}
                    handleChangeMultiple={this.handleChangeMultiple}
                    onChangeExcluirCerosONulos={this.onChangeExcluirCerosONulos}
                    tipoPeriodo={this.state.tipoPeriodo}
                  />

                  <PeriodoComponent
                    // {...this.state}
                    tipoPeriodo={this.state.tipoPeriodo}
                    handleChange={this.handleChange}
                    inicio={this.state.inicio}
                    fin={this.state.fin}
                    tipoFuente={this.state.tipoFuente}
                    periodos={this.props.download.periodos}
                    handleDate={this.handleDate}
                    onRef={ref => (this.child = ref)}
                  />
                </>)
                }

                <AperturaComponent
                  // download={download}
                  data={this.state.aperturas}
                  // aperturas={this.state.nextAperturas}
                  inputList={this.state.inputList}
                  onChangeApertura={this.onChangeApertura}
                  handleChangeApertura={this.handleChangeApertura}
                  onAddApertura={this.onAddApertura}
                  onRemoveApertura={this.onRemoveApertura}
                  inicio={this.state.inicio}
                  fin={this.state.fin}
                  tipoPeriodo={this.state.tipoPeriodo}
                />

                <DescargaComponent
                  {...this.state}
                  token={token}
                  user={user}
                  favoritos={favoritos.favorites}
                  loading={download.loading}
                  handleChange={this.handleChange}
                  handleClick={this.handleClick}
                  handleFavorito={this.handleFavorito}
                  toogleModalFavorito={actions.toogleModalFavorito}
                  modal={favoritos.modal}
                  message={favoritos.message}
                  favoritosLoading={favoritos.loading}
                />
              </CardBody>
            </Card>
          </Container>
        </>
      );
    }
    return <div> cargando ... </div>;
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { ...downloadActions.duckOperations, ...favoritesActions.duckOperations, ...b2bActions.duckOperations },
    dispatch,
  ),
  dispatch,
});

const mapStateToProps = state => ({
  b2b: state.b2bReducers,
  download: state.downloadReducers,
  user: state.authReducer.user,
  token: state.authReducer.token,
  favoritos: state.favorites,
});

/* DownloadPage.propTypes = {}; */

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPage);
