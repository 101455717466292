/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-array-index-key */
/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// react library for routing
import { Route, Switch } from 'react-router-dom';

// core components
import AuthFooter from 'components/Footers/AuthFooter.jsx';

import routes from 'routes.js';

import PrivateRoute from '../components/common/PrivateRoute'

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add('bg-info');
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      // eslint-disable-next-line react/no-string-refs
      this.refs.mainContent.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('bg-info');
  }

  getRoutes = routes =>
    routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.private) {
        return (
          <PrivateRoute exact path={prop.path} component={prop.component} key={key} />
        );
      }
      if (prop.layout === '/') {
        return (
          <Route exact path={prop.path} component={prop.component} key={key} />
        );
      }
      return null;
    });

  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
